"use strict"
if(navigator.userAgent.match(/Chrome|Chromium/) || window["chrome"]) {
    const expectedLanguage = "en-GB"
    if(
        navigator.language != expectedLanguage ||
        navigator.languages[0] != expectedLanguage
    ) {
        Date.prototype.toLocaleDateString = function(locales, options) {
            /* The default value for each date-time component property is
            /* undefined, but if the weekday, year, month, day properties are
            /* all undefined, then year, month, and day are assumed to be
            /* "numeric". */
            options = options || {}
            if(
                options.weekday ||
                options.year ||
                options.month ||
                options.day
            ) {
                // Nothing
            } else {
                Object.assign(options, {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric"
                })
            }
            return new Intl.DateTimeFormat(locales || expectedLanguage, options).format(this)
        }
        Date.prototype.toLocaleString = function(locales, options) {
            /* The default value for each date-time component property is
            /* undefined, but if the weekday, year, month, day, hour, minute,
            /* second properties are all undefined, then year, month, day, hour,
            /* minute, and second are assumed to be "numeric". */
            options = options || {}
            if(
                options.weekday ||
                options.year ||
                options.month ||
                options.day ||
                options.hour ||
                options.minute ||
                options.second
            ) {
                // Nothing
            } else {
                Object.assign(options, {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric",
                })
            }
            return new Intl.DateTimeFormat(locales || expectedLanguage, options).format(this)
        }
        Date.prototype.toLocaleTimeString = function(locales, options) {
            /* The default value for each date-time component property is
            /* undefined, but if the hour, minute, second properties are all
            /* undefined, then hour, minute, and second are assumed to be
            /* "numeric". */
            options = options || {}
            if(
                options.hour ||
                options.minute ||
                options.second
            ) {
                // Nothing
            } else {
                Object.assign(options, {
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric",
                })
            }
            return new Intl.DateTimeFormat(locales || expectedLanguage, options).format(this)
        }
        Number.prototype.toLocaleString = function(locales, options) {
            return new Intl.NumberFormat(locales || expectedLanguage, options).format(+this)
        }
        String.prototype.localeCompare = function(compareString, locales, options) {
            return new Intl.Collator(locales || expectedLanguage, options).compare("" + this, compareString)
        }
    }
}
